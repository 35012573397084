<template>
  <LayoutMainPages>
    <header class="drops-header">
      <Title :title="$tc('navigation.drops')" />
      <WhatAreDrops />
    </header>
    <DropsList
      class="mt-n-4xl md:mt-n-3xl"
      :drops="getDrops"
      :timeNow="getCurrentTime"
    />
  </LayoutMainPages>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DropsList } from '@/modules/drops';
import { LayoutMainPages, Title } from '@/components/misc';
import WhatAreDrops from '@/components/drops/WhatAreDrops.vue';
import DROP_REMAINING from '@/graphql/subscriptions/DropRemaining.gql';

export default {
  name: 'Drops',
  components: {
    DropsList,
    LayoutMainPages,
    Title,
    WhatAreDrops,
  },

  data() {
    return {
      dropRemainingSubscription: null,
      timeNowInterval: null,
    };
  },

  computed: {
    ...mapGetters('drops', ['getDrops', 'getCurrentTime']),
  },

  async mounted() {
    if (!this.globalIsLoading) {
      // if page is already loaded when mounted, it means this is the initial loading (i.e. page refresh)
      await this.loaded();
    }

    this.timeNowInterval = setInterval(() => {
      this.updateCurrentTime(new Date().getTime());
    }, 1000);

    try {
      this.dropRemainingSubscription = await this.$apollo
        .subscribe({
          query: DROP_REMAINING,
          variables: {
            pair: this.$route.params.pair,
            resolution: 'min15',
          },
        })
        .subscribe((response) => {
          const { drop_remaining } = response.data;

          this.updateDropRemaining(drop_remaining);
        });
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        //eslint-disable-next-line no-console
        console.log(err);
      }
    }
  },

  beforeDestroy() {
    if (this.dropRemainingSubscription) {
      this.dropRemainingSubscription.unsubscribe();
    }
    clearInterval(this.timeNowInterval);
  },

  methods: {
    ...mapActions('drops', ['updateCurrentTime', 'updateDropRemaining']),
    async loaded() {
      await this.$store.dispatch('events/track', {
        event: 'DROPS_VIEWED',
      });
    },
  },

  watch: {
    globalIsLoading(newValue, oldValue) {
      if (oldValue || !newValue) {
        this.$nextTick(() => {
          this.loaded();
        });
      }
    },
  },
};
</script>

<style scoped>
.drops-header {
  @apply flex justify-between items-center;
}
</style>
