<template>
  <ButtonV2
    @onClick="open"
    data-cy="drop-what-are-drops"
    :label="$t('drops.what_are_drops')"
    size="medium"
    testId="btn-whatAreDrops"
    version="secondary"
  />
</template>

<script>
import { ButtonV2 } from '@/components/misc';

export default {
  name: 'WhatAreDrops',
  components: {
    ButtonV2,
  },

  methods: {
    open() {
      this.$store.commit('ui/showPrompt', {
        title: this.$t('drops.what_are_drops'),
        type: 'success',
        message: this.$t('drops.drops_page_a_drop_is'),
      });
    }
  }


};
</script>

<style scoped></style>
